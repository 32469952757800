<template>
  <div class="branch-form">
    <v-form class="form" ref="form">
      <v-row>
        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{ $t("employee_ot.detail") }}</label>
          <v-textarea
            label="Details"
            auto-grow
            outlined
            rows="3"
            row-height="15"
            v-model="details"
          ></v-textarea>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{ $t("employee_ot.approve_by") }}</label>

          <v-autocomplete
            filled
            dense
            item-text="name"
            item-value="id"
            outlined
            :items="listManager"
            hide-selected
            v-model="approve_by"
          >
            <!-- @change="fetchData" -->
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{ $t("employee_ot.date") }}</label>
          <DatePicker
            class="date-time"
            style="width: 100%"
            type="date"
            valueType="format"
            slot="activator"
            :append-to-body="true"
            name="founding_date"
            v-model="date"
          ></DatePicker>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8" class="mt-2">
          <v-row class="mt-3">
            <v-col cols="5" md="5">
              <v-text-field v-model="check_in" type="time"></v-text-field>
            </v-col>
            <v-col cols="2" md="2" class="text-center lfont">{{
              $t("employee_ot.time-bw")
            }}</v-col>
            <v-col cols="5" md="5">
              <v-text-field v-model="check_out" type="time"></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{
            $t("employee_ot.total_minute")
          }}</label>
          <v-text-field outlined dense v-model="total_hour"></v-text-field>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="8" class="mb-16">
          <v-btn class="btn-save-change" @click="saveChange()">
            {{ $t("employee_ot.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  data() {
    return {
      listManager: [],
      date: "",
      details: "",
      check_in: "",
      check_out: "",
      approve_by: "",
      employee_ot_request_id: "",
      server_errors: {
        date: "",
        check_in: "",
        check_out: "",
        approve_by: "",
      },
    };
  },
  methods: {
    fetchManager() {
      this.$axios.get(`only/line/managers`, {}).then((res) => {
        if (res.status === 200) {
          this.listManager = res.data.data;
        }
      });
    },
    fetchEmployee_ot_item() {
      this.$axios
        .get(`employee/request/ot/${this.employee_ot_request_id}`)
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            this.approve_by = res.data.data.approve_by;
            this.date = res.data.data.date;
            this.details = res.data.data.details;
            this.check_in = res.data.data.check_in;
            this.check_out = res.data.data.check_out;
          }
        });
    },
    saveChange() {
      const items = {
        date: this.date,
        details: this.details,
        check_in: this.check_in,
        check_out: this.check_out,
        approve_by: this.approve_by,
      };
      this.$axios
        .put(`employee/request/ot/${this.employee_ot_request_id}`, items)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.$router
              .push({
                name: "employee_ot_request.index",
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  computed: {
    total_hour: function() {
      if (this.check_in != "" && this.check_out != "") {
        let start = moment(this.check_in, "HH:mm");
        let end = moment(this.check_out, "HH:mm");
        return (end - start) / 60 / 1000;
      }
      return 0;
    },
  },
  created() {
    this.employee_ot_request_id = this.$route.params.employee_ot_request_id;
    this.fetchEmployee_ot_item();
    this.fetchManager();
  },
};
</script>

<style lang="scss" scoped>
.branch-form {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 800px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
